import { ICustomer } from 'shared/domain/entities';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import ICustomersRepository, {
  TCreateParams,
  TCustomerUpdateParams,
} from 'shared/domain/repositories/ICustomersRepository';
import { Customer, IVectisCustomer } from 'shared/infra/adapters';

type TVectisUpdateParams = {
  email?: string;
  cpf?: string;
  rg?: string;
  nome?: string;
  sobrenome?: string;
  foneCelular?: string;
  dataNascimento?: string;
  cep?: string;
  logradouro?: string;
  numeroLogradouro?: string;
  idCidade?: number;
  idBairro?: number;
  numeroDocumentoFiscal?: string;
  complemento?: string;
  responsavel?: {
    nome?: string;
    sobrenome?: string;
    dataNascimento?: string;
    foneCelular?: string;
    cpf?: string;
    rg?: string;
    cep?: string;
    logradouro?: string;
    numeroLogradouro?: string;
    idCidade?: number;
    idBairro?: number;
    numeroDocumentoFiscal?: string;
    complemento?: string;
  };
};

const DEFAULT_UPDATE_ORIGIN = 'SITE';

export default class VectisCustomersRepository implements ICustomersRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public async create({
    name,
    email,
    countryCode,
    origin,
    phone,
    terms,
    unitId,
    recommenderId,
    recommenderUnitId,
    optin,
    catalogId,
    contactTypeId,
    leadOrigin,
    registerType,
    trackingMeta = {},
  }: TCreateParams): Promise<ICustomer> {
    const [firstName, ...lastNames] = name.split(' ');

    const customer = await this.httpProvider.put<IVectisCustomer>('/clientes', {
      nome: firstName,
      sobrenome: lastNames.join(' '),
      email,
      ddi: countryCode,
      foneCelular: phone,
      idUnidade: unitId,
      tipoPessoa: 'FISICA',
      origemCadastro: origin,
      statusEmail: 'VALIDO',
      statusWhatsapp: 'VALIDO',
      idClienteIndicacao: recommenderId,
      idUnidadeIndicador: recommenderUnitId,
      termoPoliticaPrivacidade: terms,
      idTipoContato: contactTypeId,
      idCatalogoProcedencia: catalogId,
      optin,
      ...trackingMeta,
      ...this.generateExtraPayloadBaseOnRegisterType(registerType),
      origemLead: this.generateLeadOrigin(leadOrigin),
    });

    return new Customer(customer);
  }

  public async update({
    id,
    ...params
  }: TCustomerUpdateParams): Promise<ICustomer> {
    const body: TVectisUpdateParams = this.buildUpdateBody(params);
    const customer = await this.httpProvider.post<IVectisCustomer>(
      `clientes/${id}`,
      body,
    );

    return new Customer(customer);
  }

  public async findById(id: number): Promise<ICustomer | null> {
    const customer = await this.httpProvider.get<IVectisCustomer | null>(
      `/clientes/${id}`,
    );

    if (!customer) return null;

    return new Customer(customer);
  }

  public async findByEmail(email: string): Promise<ICustomer | null> {
    const customer = await this.httpProvider.get<IVectisCustomer | null>(
      `/clientes`,
      {
        params: { email },
      },
    );

    if (!customer) return null;

    return new Customer(customer);
  }

  public async findByDocument(document: string): Promise<ICustomer | null> {
    const customer = await this.httpProvider.get<IVectisCustomer | null>(
      `/clientes`,
      {
        params: { cpfCnpj: document },
      },
    );

    if (!customer) return null;
    return new Customer(customer);
  }

  private generateExtraPayloadBaseOnRegisterType(
    type?: TCreateParams['registerType'],
  ) {
    if (type === 'LANDING_PAGE_RECOMMENDATION') {
      return {
        indicacaoLandingPage: true,
      };
    }

    return {};
  }

  private generateLeadOrigin(leadOrigin?: TCreateParams['leadOrigin']) {
    if (!leadOrigin) return;

    return {
      identificador: leadOrigin.key,
      canal: leadOrigin.channel,
      extra: leadOrigin.meta,
    };
  }

  private buildUpdateBody(params: Omit<TCustomerUpdateParams, 'id'>) {
    const customerBody = {
      cep: params.address?.postalCode,
      idCidade: params.address?.city?.id,
      idBairro: params.address?.district?.id,
      logradouro: params.address?.street,
      numeroLogradouro: params.address?.number,
      complemento: params.address?.additionalInformation,
      email: params.email,
      foneCelular: params.phone,
      dataNascimento: params.birthdate,
      cpf: params.document?.replaceAll('_', ''),
      rg: params.alternativeDocument,
      numeroDocumentoFiscal: params.alternativeDocument,
      origemAtualizacao: params.origin || DEFAULT_UPDATE_ORIGIN,
    };

    if (params.minorityStatus === 'ADULT') return customerBody;

    return {
      ...customerBody,
      responsavel: params.guardian
        ? {
            nome: params.guardian.firstName,
            sobrenome: params.guardian.lastName,
            cep: params.guardian.address?.postalCode,
            idCidade: params.guardian.address?.city?.id,
            idBairro: params.guardian.address?.district?.id,
            logradouro: params.guardian.address?.street,
            numeroLogradouro: params.guardian.address?.number,
            complemento: params.guardian.address?.additionalInformation,
            cpf: params.guardian.document?.replaceAll('_', ''),
            rg: params.guardian.alternativeDocument,
            numeroDocumentoFiscal: params.guardian.alternativeDocument,
            dataNascimento: params.guardian.birthdate,
            email: params.guardian.email,
            foneCelular: params.guardian.phone,
          }
        : undefined,
    };
  }
}
